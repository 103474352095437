import { RemixBrowser } from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import React, { StrictMode, startTransition } from 'react';
import { hydrateRoot } from 'react-dom/client';
import {
  initializeRouteLoadTimeTracking,
  setUpWebVitalsMetricsCollection,
  trackPreReactTime,
} from 'src/services/analytics/performance';
import { authenticationService } from 'src/shared';

import { ClientCacheProvider } from './emotion';

const DEPLOYMENT_ENV = import.meta.env.VITE_DEPLOYMENT_ENV || 'LOCAL';

const environment = DEPLOYMENT_ENV.toLowerCase();
const release = import.meta.env.VITE_SENTRY_VERSION;

Sentry.init({
  dsn: `https://da2d68b1b0974da5a44539e36dfbd079@o217046.ingest.sentry.io/1358652`,
  maxValueLength: 4096,
  environment,
  release,
  beforeSend: (event) => {
    if (['production', 'staging'].includes(environment)) {
      return event;
    }
    return null;
  },
});

setUpWebVitalsMetricsCollection();
initializeRouteLoadTimeTracking();

trackPreReactTime();

authenticationService.init().then(() =>
  startTransition(() => {
    hydrateRoot(
      document,
      <StrictMode>
        <ClientCacheProvider>
          <RemixBrowser />
        </ClientCacheProvider>
      </StrictMode>
    );
  })
);
